//import { NOTIFICATIONS_LAYER } from '@/lib/constants/layers';
import { DEVICES_PANEL_ID } from '@/lib/variables/panels';
import { INITIAL_BOUNDS_KPIS } from '@/components/map/sections/map/subcomponents/mapVariables';

export default {
	global: {
		appVersion: '1.0.0',
		appDefaultEntryPoint: 'home',
		multiPanel: false,
		baseUrl: '/sp',

		appContainerHeader: true,
		appLoginDisplayArea: false,
		appLoginActions: true,
		appUserPanelElement: false,
		containerActionHeader: false,
		langContainerActionHeader: false,
		webSocketsEnabled: false,
		appHeaderMapHeightInPx: 48,
		primaryColor: '#014EFA',
		singleItemAutoselectOnRequired: true
	},

	login: {
		forgetPassword: true
	},

	menu: {
		homeBtn: false,
		searchBtn: false,
		notificationsBtn: false,
		hideMenuBtn: true
	},

	/**
	 * App data
	 */
	data: {
		application: null,
		// Notifications Data Inserted Here
		notifications: {
			viewportFeatures: [],
			statusHistogram_byArea: {},
			statusHistogram_byDatasourcetype: {},
			counts_byArea: {
				global: 0,
				viewport: 0
			},
			counts_byDatasourcetype: {
				global: 0,
				viewport: 0
			},
			limit: 100,
			offset: 0
		},
		rules: null,
		timeunits: null,
		parameters: null,
		severities: null,
		icons: null,
		urlBaseIcons: null,
		errorCodes: [],
		notificationData: null,
		areas: null,
		selectedAreas: [],
		datasourcetype: null,
		form: {
			formFilters: null,
			areaDefinitions: null,
			filterData: { groupOp: 'and', groups: [], rules: [] },
			statusFilters: null
		}
	},
	//TODO: finish this store implementation
	layers: {
		notificationsLayer: 'notifications',
		layerNamesKpisArea: null,
		layerNamesKpisDatasourcetype: null
	},

	elastic: {
		popupData: null,
		loadingPopup: false,
		realTimeData: [],
		datasourceRealTimeData: [],
		historicData: [],
		predictionData: [],
		historicFirstDates: [],
		historicLastDates: [],
		predictionFirstDates: [],
		predictionLastDates: [],
		advancedHistoricData: [],
		advancedDatasourceData: [],
		compareAqiData: null,
		compareAqiFirstDates: [],
		compareAqiLastDates: []
	},

	form: {
		showSaveAndUpdate: false,
		showSaveAndNew: false
	},

	settingsPanel: {
		isOpened: {
			filters: false,
			layers: false,
			layersrasterSettings: false,
			externalrasterlayers: false,
			aqilayers: false
		},
		isRasterActive: false,
		activeRasterLayer: null,
		isGeoJsonActive: false,
		activeGeoJsonLayer: null
	},

	compareMapPanel: {
		isOpened: {
			filters: false,
			layersrasterCompareMap1: false,
			layersrasterCompareMap2: false
		},
		isRasterCompareMap1Active: false,
		isRasterCompareMap2Active: false,
		activeCompareMap1RasterLayer: null,
		activeCompareMap2RasterLayer: null
	},

	devicesPanel: {
		isOpened: {
			filters: false,
			devices: false
		}
	},
	aqiPointsPanel: {
		isOpened: {
			filters: false,
			aqipoints: false
		}
	},

	/**
	 * Map Panel Options
	 */
	mappanel: {
		loading: 0,
		refreshTime: null,

		filterPanel: {
			notifications: false
		},

		kpisNotificationsFilters: {
			selected: null
		},

		/**
		 * Table of content Buttons
		 */

		rasterOpacity: 50,
		rasterOpacityCompareMap1: 50,
		rasterOpacityCompareMap2: 50,
		geoJsonOpacity: 50,

		/**
		 * Notifications KPI Bars
		 */
		notifications_byArea: {
			selectedKpi: null,
			filtersPanelOpened: false
		},

		notifications_byDatasourcetype: {
			selectedKpi: null,
			filtersPanelOpened: false
		},

		/**
		 * Panels
		 */
		activePanel: DEVICES_PANEL_ID,
		activePanelOptions: null,
		dynamicPanels: [],
		dynamicPanelsId: [],

		/**
		 * Map Settings
		 */
		zoom: null,
		originalZoom: 12,
		bbox: INITIAL_BOUNDS_KPIS,

		/**
		 * Map Panels Data
		 */
		layers: {},
		notificationLayers: null,
		rasterLayersSettings: null,
		rasterLayersCompareMap1: null,
		rasterLayersCompareMap2: null,
		rasterLayersData: null,
		rasterDateSelected: [null, null, null],
		rasterSelectedImage: [],
		devices: null,
		devicesData: null,
		deviceZoom: null,
		aqipoints: null,
		compareMap: null,
		externalLayers: {},
		geoJsonLayers: {},
		geoJsonLayersData: null,
		parameters: null,
		parameterStatus: null,
		satelliteParameters: null,
		actions: null,
		actionsLogs: null,
		devicesListObject: null,

		/**
		 * MapPanelInfo Visibility Booleans
		 */
		hasDevices: false,
		hasAqiPoints: false,
		hasCompareMap: false,
		hasLayers: false,
		hasExternalLayers: false,
		featureCard: {
			featureClick: false,
			featureType: null,
			featurePopupClick: false,
			displayMode: 'card',
			selectedTabDevice: null,
			selectedTabDatasource: null,
			selectedTabHistoric: null,
			selectedTabAdvanced: 'advanced-device',
			selectedTabPrediction: null,
			selectedTabNotification: null,
			selectedTabNotificationDialog: null,
			formHistoric: [],
			formAdvanced: [],
			formAdvancedDatasource: [],
			formPredictions: [],
			formXAI: [],
			formCompareAqi: null,
			hasHistoricNotifications: null,
			aqiDescriptions: null,
			fullscreenDialog: false
		}
	},

	/**
	 * Map Settings
	 */
	map: {
		legendExpanded: false,
		mapLoaded: false,
		mapLoadedLayersFinish: false,
		centerCoordinates: null
		//eventClick: false
	},

	mapCompare: {
		legendExpanded: false,
		mapLoaded: false
		//eventClick: false
	},

	user: {
		properties: null,
		permissions: {}
	},

	areas: {
		properties: null
	},

	datasourcetype: {
		properties: null
	},

	dashboard: {
		slaDevices: null,
		dashboardTab: null,
		contentTab: null,
		slaChartFilter: null,
		slaDate: null
	},
	misc: {
		loadingDialog: false,
		collapsedInfo: false
	}
};
