export const SETTINGS_PANEL_ID = 'settings';
export const NOTIFICATIONS_PANEL_ID = 'notifications';
export const DEVICES_PANEL_ID = 'devices';
export const AQIPOINTS_PANEL_ID = 'aqipoints';
export const COMPAREMAPS_PANEL_ID = 'comparemaps';
export const SATELLITE_PANEL_ID = 'satellite';

// FeatureCardItem variables
export const CARD_HEIGHT = 476;
export const CARD_CONTAINER_HEIGHT = 432; // tabs & content
export const CARD_CONTAINER_HEIGHT_DIALOG = '70vh'; // tabs & content
export const CARD_TABS_HEIGHT = 48;
